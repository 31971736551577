/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContainerMovementLedgerEventTypeEnum,
    ContainerMovementLedgerEventTypeEnumFromJSON,
    ContainerMovementLedgerEventTypeEnumFromJSONTyped,
    ContainerMovementLedgerEventTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContainerMovementLedger
 */
export interface ContainerMovementLedger {
    /**
     * 
     * @type {string}
     * @memberof ContainerMovementLedger
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerMovementLedger
     */
    readonly site: string;
    /**
     * 
     * @type {ContainerMovementLedgerEventTypeEnum}
     * @memberof ContainerMovementLedger
     */
    eventType: ContainerMovementLedgerEventTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ContainerMovementLedger
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ContainerMovementLedger
     */
    readonly containerType: string;
}

export function ContainerMovementLedgerFromJSON(json: any): ContainerMovementLedger {
    return ContainerMovementLedgerFromJSONTyped(json, false);
}

export function ContainerMovementLedgerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerMovementLedger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'site': json['site'],
        'eventType': ContainerMovementLedgerEventTypeEnumFromJSON(json['event_type']),
        'createdAt': (new Date(json['created_at'])),
        'containerType': json['container_type'],
    };
}

export function ContainerMovementLedgerToJSON(value?: ContainerMovementLedger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_type': ContainerMovementLedgerEventTypeEnumToJSON(value.eventType),
    };
}


