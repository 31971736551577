import * as Sentry from '@sentry/nextjs';

import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { VendorsProvider } from 'components/VendorsContext';
import { StripeIntentProvider } from 'StripeIntentContext';
import { unprotectedRoutes } from 'utilities/constants';
import { UserProvider } from './UserContext';

type AuthenticationType = {
  children: ReactNode;
  router: any;
};

export const Authentication = ({ children, router }: AuthenticationType): JSX.Element | null => {
  const { isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const isPublicPath = unprotectedRoutes.includes(router.pathname);

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.NEXT_PUBLIC_AUTH_API,
          scope: 'openid profile email offline_access',
        });

        setAccessToken(accessToken);
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isPublicPath && !isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          targetUrl: typeof window !== undefined && `${window.location.href}`,
        },
        display: 'page',
      });
    }
  }, [isAuthenticated, isLoading]);

  if (isPublicPath) return <>{children}</>;

  return isAuthenticated && accessToken ? (
    <AccessTokenContext.Provider value={accessToken}>
      <UserProvider>
        <StripeIntentProvider>
        <VendorsProvider>{children}</VendorsProvider>
        </StripeIntentProvider>
      </UserProvider>
    </AccessTokenContext.Provider>
  ) : null;
};

const AccessTokenContext = createContext<string | undefined>(undefined);

export const useAccessToken = (): string => {
  const accessToken = useContext(AccessTokenContext);
  if (accessToken === undefined) throw Error(`AccessTokenContext used outside of provider.`);
  return accessToken;
};
