/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PromotionSummary,
    PromotionSummaryFromJSON,
    PromotionSummaryFromJSONTyped,
    PromotionSummaryToJSON,
    VendorReward,
    VendorRewardFromJSON,
    VendorRewardFromJSONTyped,
    VendorRewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorPromotions
 */
export interface VendorPromotions {
    /**
     * 
     * @type {Array<PromotionSummary>}
     * @memberof VendorPromotions
     */
    activePromotions: Array<PromotionSummary>;
    /**
     * 
     * @type {Array<VendorReward>}
     * @memberof VendorPromotions
     */
    rewardTransactions: Array<VendorReward>;
}

export function VendorPromotionsFromJSON(json: any): VendorPromotions {
    return VendorPromotionsFromJSONTyped(json, false);
}

export function VendorPromotionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorPromotions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activePromotions': ((json['active_promotions'] as Array<any>).map(PromotionSummaryFromJSON)),
        'rewardTransactions': ((json['reward_transactions'] as Array<any>).map(VendorRewardFromJSON)),
    };
}

export function VendorPromotionsToJSON(value?: VendorPromotions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_promotions': ((value.activePromotions as Array<any>).map(PromotionSummaryToJSON)),
        'reward_transactions': ((value.rewardTransactions as Array<any>).map(VendorRewardToJSON)),
    };
}


