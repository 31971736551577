/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Profile,
    ProfileFromJSON,
    ProfileFromJSONTyped,
    ProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface PatchedUser
 */
export interface PatchedUser {
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    lastName?: string;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof PatchedUser
     */
    readonly username?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    email?: string;
    /**
     * 
     * @type {Profile}
     * @memberof PatchedUser
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    redeemReferralCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    rapidSignupVerificationCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    merged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    assignGtinPool?: string;
}

export function PatchedUserFromJSON(json: any): PatchedUser {
    return PatchedUserFromJSONTyped(json, false);
}

export function PatchedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'profile': !exists(json, 'profile') ? undefined : ProfileFromJSON(json['profile']),
        'redeemReferralCode': !exists(json, 'redeem_referral_code') ? undefined : json['redeem_referral_code'],
        'rapidSignupVerificationCode': !exists(json, 'rapid_signup_verification_code') ? undefined : json['rapid_signup_verification_code'],
        'merged': !exists(json, 'merged') ? undefined : json['merged'],
        'assignGtinPool': !exists(json, 'assign_gtin_pool') ? undefined : json['assign_gtin_pool'],
    };
}

export function PatchedUserToJSON(value?: PatchedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'profile': ProfileToJSON(value.profile),
        'redeem_referral_code': value.redeemReferralCode,
        'rapid_signup_verification_code': value.rapidSignupVerificationCode,
        'merged': value.merged,
        'assign_gtin_pool': value.assignGtinPool,
    };
}


