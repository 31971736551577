/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SiteWithContainerType,
    SiteWithContainerTypeFromJSON,
    SiteWithContainerTypeFromJSONTyped,
    SiteWithContainerTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SiteContainersByTag
 */
export interface SiteContainersByTag {
    /**
     * 
     * @type {string}
     * @memberof SiteContainersByTag
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof SiteContainersByTag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SiteContainersByTag
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SiteContainersByTag
     */
    readonly availablePromotions: string;
    /**
     * 
     * @type {SiteWithContainerType}
     * @memberof SiteContainersByTag
     */
    site: SiteWithContainerType;
}

export function SiteContainersByTagFromJSON(json: any): SiteContainersByTag {
    return SiteContainersByTagFromJSONTyped(json, false);
}

export function SiteContainersByTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteContainersByTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'availablePromotions': json['available_promotions'],
        'site': SiteWithContainerTypeFromJSON(json['site']),
    };
}

export function SiteContainersByTagToJSON(value?: SiteContainersByTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'site': SiteWithContainerTypeToJSON(value.site),
    };
}


