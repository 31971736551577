import { GlobalStyles, aaTheme } from 'theme/aa/aa-theme';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Authentication } from 'components/Authentication';
import { ChakraProvider } from '@chakra-ui/react';
import Router from 'next/router';

const AppWrapper = ({ Component, pageProps, router }) => {
  const onRedirectCallback = (appState?: AppState) => {
    Router.replace(appState?.targetUrl || appState?.returnTo || '/');
  };
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH_DOMAIN!}
      clientId={process.env.NEXT_PUBLIC_AUTH_CLIENT_ID!}
      redirectUri={process.env.NEXT_PUBLIC_AUTH_BASE_URL}
      scope="openid profile email offline_access"
      audience={process.env.NEXT_PUBLIC_AUTH_API}
      onRedirectCallback={onRedirectCallback}
    >
      <Authentication router={router}>
        <ChakraProvider theme={aaTheme}>
          <GlobalStyles />
          <Component {...pageProps} />
        </ChakraProvider>
      </Authentication>
    </Auth0Provider>
  );
};

export default AppWrapper;
