/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContainerCategory
 */
export interface ContainerCategory {
    /**
     * 
     * @type {string}
     * @memberof ContainerCategory
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerCategory
     */
    description: string;
}

export function ContainerCategoryFromJSON(json: any): ContainerCategory {
    return ContainerCategoryFromJSONTyped(json, false);
}

export function ContainerCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
    };
}

export function ContainerCategoryToJSON(value?: ContainerCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}


