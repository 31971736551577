import { useEffect, useState } from 'react';
import { useAccessToken } from '../components/Authentication';

import { ApiApi, Configuration } from '../__generated__';

export const useAPI = () => {
  const accessToken = useAccessToken();
  return getAPI(accessToken);
};

type LoadingState<T> = { data: T | undefined; error: any | undefined; isLoading: boolean };

export const useLoadingState = <T,>(loader: () => Promise<T>): LoadingState<T> => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    loader()
      .then((data) => {
        setData(data);
        setIsLoading(false);
        setError(undefined);
      })
      .catch((e: any) => {
        setError(e);
        setData(undefined);
        setIsLoading(false);
      });
  }, [loader]);

  return {
    isLoading,
    data,
    error,
  };
};

const getAPI = (accessToken: string) => {
  const config = new Configuration({
    accessToken: () => accessToken,
    basePath: `${process.env.NEXT_PUBLIC_API}`,
  });
  return new ApiApi(config);
};
