/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country: string;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addressLine1': json['address_line_1'],
        'addressLine2': !exists(json, 'address_line_2') ? undefined : json['address_line_2'],
        'city': json['city'],
        'state': json['state'],
        'postcode': json['postcode'],
        'country': json['country'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_line_1': value.addressLine1,
        'address_line_2': value.addressLine2,
        'city': value.city,
        'state': value.state,
        'postcode': value.postcode,
        'country': value.country,
    };
}


