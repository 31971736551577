export const Consumer = {
  parts: ['ConsumerContainer'],
  baseStyle: {
    ConsumerContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      
    },
    ConsumerMain: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      
    },
    PageContainer: {
      width: '400px',
    }
  },
  variants: {
    mobile: {
      PageContainer: {
        width: '100%',
      }
    },
  },
  defaultProps: {
    variants: 'light',
  },
};
