/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryEnum,
    CategoryEnumFromJSON,
    CategoryEnumFromJSONTyped,
    CategoryEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface Milestone
 */
export interface Milestone {
    /**
     * 
     * @type {string}
     * @memberof Milestone
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Milestone
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Milestone
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Milestone
     */
    image: string;
    /**
     * 
     * @type {number}
     * @memberof Milestone
     */
    count: number;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof Milestone
     */
    category: CategoryEnum;
}

export function MilestoneFromJSON(json: any): Milestone {
    return MilestoneFromJSONTyped(json, false);
}

export function MilestoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Milestone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'image': json['image'],
        'count': json['count'],
        'category': CategoryEnumFromJSON(json['category']),
    };
}

export function MilestoneToJSON(value?: Milestone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'image': value.image,
        'count': value.count,
        'category': CategoryEnumToJSON(value.category),
    };
}


