/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Contact,
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    name: string;
    /**
     * 
     * @type {Address}
     * @memberof Site
     */
    address: Address;
    /**
     * 
     * @type {Contact}
     * @memberof Site
     */
    primaryContact: Contact;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    readonly latitude: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    readonly longitude: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Site
     */
    readonly siteContainerTypes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Site
     */
    sitePromotions?: Array<string>;
}

export function SiteFromJSON(json: any): Site {
    return SiteFromJSONTyped(json, false);
}

export function SiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Site {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'address': AddressFromJSON(json['address']),
        'primaryContact': ContactFromJSON(json['primary_contact']),
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'siteContainerTypes': json['site_container_types'],
        'sitePromotions': !exists(json, 'site_promotions') ? undefined : json['site_promotions'],
    };
}

export function SiteToJSON(value?: Site | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': AddressToJSON(value.address),
        'primary_contact': ContactToJSON(value.primaryContact),
        'site_promotions': value.sitePromotions,
    };
}


