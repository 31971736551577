/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    marketingOptIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    onboardingCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    firstTopUpScreenSkipped?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    readonly firstTopUpCompleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    preferredName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    referralCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    referralCodeApplied?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    readonly hasValidPaymentMethod: Date;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    readonly lastPaymentFailed: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    readonly canBorrow: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    readonly cardExpiryDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly cardLast4Digits: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly gtin: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    rapidSignupVerificationCode?: string | null;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketingOptIn': !exists(json, 'marketing_opt_in') ? undefined : json['marketing_opt_in'],
        'onboardingCompleted': !exists(json, 'onboarding_completed') ? undefined : json['onboarding_completed'],
        'firstTopUpScreenSkipped': !exists(json, 'first_top_up_screen_skipped') ? undefined : json['first_top_up_screen_skipped'],
        'firstTopUpCompleted': json['first_top_up_completed'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'referralCode': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'referralCodeApplied': !exists(json, 'referral_code_applied') ? undefined : (new Date(json['referral_code_applied'])),
        'hasValidPaymentMethod': (new Date(json['has_valid_payment_method'])),
        'lastPaymentFailed': (new Date(json['last_payment_failed'])),
        'canBorrow': json['can_borrow'],
        'cardExpiryDate': (new Date(json['card_expiry_date'])),
        'cardLast4Digits': json['card_last_4_digits'],
        'gtin': json['gtin'],
        'rapidSignupVerificationCode': !exists(json, 'rapid_signup_verification_code') ? undefined : json['rapid_signup_verification_code'],
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketing_opt_in': value.marketingOptIn,
        'onboarding_completed': value.onboardingCompleted,
        'first_top_up_screen_skipped': value.firstTopUpScreenSkipped,
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'referral_code': value.referralCode,
        'referral_code_applied': value.referralCodeApplied === undefined ? undefined : (value.referralCodeApplied.toISOString()),
        'rapid_signup_verification_code': value.rapidSignupVerificationCode,
    };
}


