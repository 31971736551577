/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    site: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    encryptionKey: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    manufactureUdid: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    readonly lastScanCount: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    readonly siteName: string;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'site': json['site'],
        'encryptionKey': json['encryption_key'],
        'manufactureUdid': json['manufacture_udid'],
        'lastScanCount': json['last_scan_count'],
        'siteName': json['site_name'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'site': value.site,
        'encryption_key': value.encryptionKey,
        'manufacture_udid': value.manufactureUdid,
    };
}


