import { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAPI, useLoadingState } from 'utilities/api';

import { Vendor } from '__generated__';
import { useGetAPI } from 'utilities/getAPI';

type VendorsContextType = {
  vendors: Vendor[] | undefined;
  isLoading?: boolean;
  hasError?: boolean;
};

export const VendorsContext = createContext<VendorsContextType>({
  vendors: undefined,
});

export const VendorsProvider: FC = ({ children }) => {
  const api = useAPI();
  const chooseMethod = useCallback(() => api.apiV1VendorsSitesList(), []);
  const { data: vendors, isLoading, error: hasError } = useLoadingState(chooseMethod);

  return (
    <VendorsContext.Provider value={{ vendors, isLoading, hasError }}>
      {children}
    </VendorsContext.Provider>
  );
};

export const useVendorsContext = () => {
  const vendorsValue = useContext(VendorsContext);
  if (!vendorsValue) throw Error('VendorsContext used outside provider');
  return vendorsValue;
};
