export const SideBarNav = {
  parts: ['Nav', 'NavLink', 'NavIcon'],
  baseStyle: {
    Nav: {
      position: 'sticky',
      top: '0',
      height: 'calc(100vh - 60px)',
      width: '256px',
      flexShrink: '0',
      paddingTop: '56px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '16px',
      bgColor: 'brand.extralightgrey',
      overflowY: 'auto',
    },
    NavLink: {
      display: 'block',
      padding: '8px',
      paddingLeft: '10px',
      bgColor: 'transparent',
      borderRadius: '8px',
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: '400',
      transition: 'background-color .2s',
      ':hover': {
        textDecoration: 'none',
      },
    },
    NavIcon: {
      color: 'brand.blue',
      width: '24px',
      height: '24px',
    },
  },
  variants: {
    mobile: {
      Nav: {
        height: 'auto',
        order: '2',
        flexBasis: '100%',
        ':empty': {
          display: 'none',
        },
      },
    },
  },
  defaultProps: {
    variants: 'light',
  },
};
