/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NFCTag,
    NFCTagFromJSON,
    NFCTagFromJSONTyped,
    NFCTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Swap
 */
export interface Swap {
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    shelfId: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    appliedPromotionId: string | null;
    /**
     * 
     * @type {NFCTag}
     * @memberof Swap
     */
    tag: NFCTag;
}

export function SwapFromJSON(json: any): Swap {
    return SwapFromJSONTyped(json, false);
}

export function SwapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Swap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shelfId': json['shelf_id'],
        'appliedPromotionId': json['applied_promotion_id'],
        'tag': NFCTagFromJSON(json['tag']),
    };
}

export function SwapToJSON(value?: Swap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shelf_id': value.shelfId,
        'applied_promotion_id': value.appliedPromotionId,
        'tag': NFCTagToJSON(value.tag),
    };
}


