/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Milestone,
    MilestoneFromJSON,
    MilestoneFromJSONTyped,
    MilestoneToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReturnItem
 */
export interface ReturnItem {
    /**
     * 
     * @type {Array<Milestone>}
     * @memberof ReturnItem
     */
    milestones: Array<Milestone>;
}

export function ReturnItemFromJSON(json: any): ReturnItem {
    return ReturnItemFromJSONTyped(json, false);
}

export function ReturnItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReturnItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'milestones': ((json['milestones'] as Array<any>).map(MilestoneFromJSON)),
    };
}

export function ReturnItemToJSON(value?: ReturnItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'milestones': ((value.milestones as Array<any>).map(MilestoneToJSON)),
    };
}


