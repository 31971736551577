/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Contact,
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
    ContainerType,
    ContainerTypeFromJSON,
    ContainerTypeFromJSONTyped,
    ContainerTypeToJSON,
    Promotion,
    PromotionFromJSON,
    PromotionFromJSONTyped,
    PromotionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SiteWithContainerType
 */
export interface SiteWithContainerType {
    /**
     * 
     * @type {string}
     * @memberof SiteWithContainerType
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof SiteWithContainerType
     */
    name: string;
    /**
     * 
     * @type {Address}
     * @memberof SiteWithContainerType
     */
    address: Address;
    /**
     * 
     * @type {Contact}
     * @memberof SiteWithContainerType
     */
    primaryContact: Contact;
    /**
     * 
     * @type {string}
     * @memberof SiteWithContainerType
     */
    readonly latitude: string;
    /**
     * 
     * @type {string}
     * @memberof SiteWithContainerType
     */
    readonly longitude: string;
    /**
     * 
     * @type {Array<ContainerType>}
     * @memberof SiteWithContainerType
     */
    siteContainerTypes: Array<ContainerType>;
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof SiteWithContainerType
     */
    sitePromotions: Array<Promotion>;
}

export function SiteWithContainerTypeFromJSON(json: any): SiteWithContainerType {
    return SiteWithContainerTypeFromJSONTyped(json, false);
}

export function SiteWithContainerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteWithContainerType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'address': AddressFromJSON(json['address']),
        'primaryContact': ContactFromJSON(json['primary_contact']),
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'siteContainerTypes': ((json['site_container_types'] as Array<any>).map(ContainerTypeFromJSON)),
        'sitePromotions': ((json['site_promotions'] as Array<any>).map(PromotionFromJSON)),
    };
}

export function SiteWithContainerTypeToJSON(value?: SiteWithContainerType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': AddressToJSON(value.address),
        'primary_contact': ContactToJSON(value.primaryContact),
        'site_container_types': ((value.siteContainerTypes as Array<any>).map(ContainerTypeToJSON)),
        'site_promotions': ((value.sitePromotions as Array<any>).map(PromotionToJSON)),
    };
}


