/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reward,
    RewardFromJSON,
    RewardFromJSONTyped,
    RewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface RewardStatus
 */
export interface RewardStatus {
    /**
     * 
     * @type {Reward}
     * @memberof RewardStatus
     */
    activeRewards: Reward;
    /**
     * 
     * @type {Reward}
     * @memberof RewardStatus
     */
    inactiveRewards: Reward;
}

export function RewardStatusFromJSON(json: any): RewardStatus {
    return RewardStatusFromJSONTyped(json, false);
}

export function RewardStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewardStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeRewards': RewardFromJSON(json['active_rewards']),
        'inactiveRewards': RewardFromJSON(json['inactive_rewards']),
    };
}

export function RewardStatusToJSON(value?: RewardStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_rewards': RewardToJSON(value.activeRewards),
        'inactive_rewards': RewardToJSON(value.inactiveRewards),
    };
}


