/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    referralCode: string;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralCode': json['referral_code'],
    };
}

export function ReferralToJSON(value?: Referral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_code': value.referralCode,
    };
}


