/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShelfContainerType,
    ShelfContainerTypeFromJSON,
    ShelfContainerTypeFromJSONTyped,
    ShelfContainerTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Shelf
 */
export interface Shelf {
    /**
     * 
     * @type {string}
     * @memberof Shelf
     */
    readonly id: string;
    /**
     * 
     * @type {Date}
     * @memberof Shelf
     */
    issuedAt?: Date;
    /**
     * 
     * @type {ShelfContainerType}
     * @memberof Shelf
     */
    readonly containerType: ShelfContainerType;
    /**
     * 
     * @type {string}
     * @memberof Shelf
     */
    readonly currentProgress: string;
    /**
     * 
     * @type {string}
     * @memberof Shelf
     */
    readonly daysLeft: string;
}

export function ShelfFromJSON(json: any): Shelf {
    return ShelfFromJSONTyped(json, false);
}

export function ShelfFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shelf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'issuedAt': !exists(json, 'issued_at') ? undefined : (new Date(json['issued_at'])),
        'containerType': ShelfContainerTypeFromJSON(json['container_type']),
        'currentProgress': json['current_progress'],
        'daysLeft': json['days_left'],
    };
}

export function ShelfToJSON(value?: Shelf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issued_at': value.issuedAt === undefined ? undefined : (value.issuedAt.toISOString()),
    };
}


