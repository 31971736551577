/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContainerCategory,
    ContainerCategoryFromJSON,
    ContainerCategoryFromJSONTyped,
    ContainerCategoryToJSON,
    ContainerTypeVersion,
    ContainerTypeVersionFromJSON,
    ContainerTypeVersionFromJSONTyped,
    ContainerTypeVersionToJSON,
    TierEnum,
    TierEnumFromJSON,
    TierEnumFromJSONTyped,
    TierEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShelfContainerType
 */
export interface ShelfContainerType {
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    subtitle: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    containerMapThumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    containerShelfThumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    containerDetailImage: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    description: string;
    /**
     * 
     * @type {ContainerCategory}
     * @memberof ShelfContainerType
     */
    containerCategory: ContainerCategory;
    /**
     * 
     * @type {ContainerTypeVersion}
     * @memberof ShelfContainerType
     */
    readonly currentVersion: ContainerTypeVersion;
    /**
     * 
     * @type {TierEnum}
     * @memberof ShelfContainerType
     */
    tier: TierEnum;
    /**
     * 
     * @type {number}
     * @memberof ShelfContainerType
     */
    volumeSize: number;
    /**
     * 
     * @type {number}
     * @memberof ShelfContainerType
     */
    lateFee: number;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    readonly bondRequired: string;
    /**
     * 
     * @type {string}
     * @memberof ShelfContainerType
     */
    readonly onShelf: string;
}

export function ShelfContainerTypeFromJSON(json: any): ShelfContainerType {
    return ShelfContainerTypeFromJSONTyped(json, false);
}

export function ShelfContainerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShelfContainerType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'subtitle': json['subtitle'],
        'containerMapThumbnail': json['container_map_thumbnail'],
        'containerShelfThumbnail': json['container_shelf_thumbnail'],
        'containerDetailImage': json['container_detail_image'],
        'description': json['description'],
        'containerCategory': ContainerCategoryFromJSON(json['container_category']),
        'currentVersion': ContainerTypeVersionFromJSON(json['current_version']),
        'tier': TierEnumFromJSON(json['tier']),
        'volumeSize': json['volume_size'],
        'lateFee': json['late_fee'],
        'bondRequired': json['bond_required'],
        'onShelf': json['on_shelf'],
    };
}

export function ShelfContainerTypeToJSON(value?: ShelfContainerType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'subtitle': value.subtitle,
        'container_map_thumbnail': value.containerMapThumbnail,
        'container_shelf_thumbnail': value.containerShelfThumbnail,
        'container_detail_image': value.containerDetailImage,
        'description': value.description,
        'container_category': ContainerCategoryToJSON(value.containerCategory),
        'tier': TierEnumToJSON(value.tier),
        'volume_size': value.volumeSize,
        'late_fee': value.lateFee,
    };
}


