/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueContainer,
    IssueContainerFromJSON,
    IssueContainerFromJSONTyped,
    IssueContainerToJSON,
    NFCTag,
    NFCTagFromJSON,
    NFCTagFromJSONTyped,
    NFCTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * 
     * @type {Array<IssueContainer>}
     * @memberof Issue
     */
    containers?: Array<IssueContainer>;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    containerTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    appliedPromotionId?: string | null;
    /**
     * 
     * @type {NFCTag}
     * @memberof Issue
     */
    tag?: NFCTag;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    containerCount?: string;
}

export function IssueFromJSON(json: any): Issue {
    return IssueFromJSONTyped(json, false);
}

export function IssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Issue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(IssueContainerFromJSON)),
        'containerTypeId': !exists(json, 'container_type_id') ? undefined : json['container_type_id'],
        'appliedPromotionId': !exists(json, 'applied_promotion_id') ? undefined : json['applied_promotion_id'],
        'tag': !exists(json, 'tag') ? undefined : NFCTagFromJSON(json['tag']),
        'containerCount': !exists(json, 'container_count') ? undefined : json['container_count'],
    };
}

export function IssueToJSON(value?: Issue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(IssueContainerToJSON)),
        'container_type_id': value.containerTypeId,
        'applied_promotion_id': value.appliedPromotionId,
        'tag': NFCTagToJSON(value.tag),
        'container_count': value.containerCount,
    };
}


