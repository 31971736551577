/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    CheckUpdate,
    CheckUpdateFromJSON,
    CheckUpdateToJSON,
    ContainerCategory,
    ContainerCategoryFromJSON,
    ContainerCategoryToJSON,
    ContainerMovementLedger,
    ContainerMovementLedgerFromJSON,
    ContainerMovementLedgerToJSON,
    ContainerType,
    ContainerTypeFromJSON,
    ContainerTypeToJSON,
    DeviceRegistration,
    DeviceRegistrationFromJSON,
    DeviceRegistrationToJSON,
    Issue,
    IssueFromJSON,
    IssueToJSON,
    IssuedShelfItem,
    IssuedShelfItemFromJSON,
    IssuedShelfItemToJSON,
    PaginatedAddressList,
    PaginatedAddressListFromJSON,
    PaginatedAddressListToJSON,
    PaginatedPromotionList,
    PaginatedPromotionListFromJSON,
    PaginatedPromotionListToJSON,
    PaginatedSiteList,
    PaginatedSiteListFromJSON,
    PaginatedSiteListToJSON,
    PatchedUser,
    PatchedUserFromJSON,
    PatchedUserToJSON,
    Promotion,
    PromotionFromJSON,
    PromotionToJSON,
    RapidOnboarding,
    RapidOnboardingFromJSON,
    RapidOnboardingToJSON,
    Referral,
    ReferralFromJSON,
    ReferralToJSON,
    Return,
    ReturnFromJSON,
    ReturnToJSON,
    ReturnItem,
    ReturnItemFromJSON,
    ReturnItemToJSON,
    Reward,
    RewardFromJSON,
    RewardToJSON,
    RewardStatus,
    RewardStatusFromJSON,
    RewardStatusToJSON,
    Shelf,
    ShelfFromJSON,
    ShelfToJSON,
    Site,
    SiteFromJSON,
    SiteToJSON,
    SiteContainersByTag,
    SiteContainersByTagFromJSON,
    SiteContainersByTagToJSON,
    StripePaymentIntent,
    StripePaymentIntentFromJSON,
    StripePaymentIntentToJSON,
    Swap,
    SwapFromJSON,
    SwapToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserBalance,
    UserBalanceFromJSON,
    UserBalanceToJSON,
    UserImpact,
    UserImpactFromJSON,
    UserImpactToJSON,
    Username,
    UsernameFromJSON,
    UsernameToJSON,
    Vendor,
    VendorFromJSON,
    VendorToJSON,
    VendorImpact,
    VendorImpactFromJSON,
    VendorImpactToJSON,
    VendorInventories,
    VendorInventoriesFromJSON,
    VendorInventoriesToJSON,
    VendorPromotions,
    VendorPromotionsFromJSON,
    VendorPromotionsToJSON,
} from '../models';

export interface ApiV1AddressesListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiV1AddressesRetrieveRequest {
    id: string;
}

export interface ApiV1CheckUpdateRetrieveRequest {
    appVersion?: string;
    platform?: string;
}

export interface ApiV1ContainerIssueCreateRequest {
    issue?: Issue;
}

export interface ApiV1ContainerReturnCreateRequest {
    _return?: Return;
}

export interface ApiV1ContainerSwapCreateRequest {
    swap: Swap;
}

export interface ApiV1ContainerTypeCategoriesRetrieveRequest {
    id: string;
}

export interface ApiV1ContainerTypesRetrieveRequest {
    id: string;
}

export interface ApiV1DevShelfAgeMyShelfRetrieveRequest {
    days?: number;
}

export interface ApiV1DevShelfCreateVendorMockDataRetrieveRequest {
    siteId?: string;
}

export interface ApiV1DevicesRegisterCreateRequest {
    deviceRegistration: DeviceRegistration;
}

export interface ApiV1ProfileMePartialUpdateRequest {
    patchedUser?: PatchedUser;
}

export interface ApiV1ProfileMeUpdateRequest {
    user: User;
}

export interface ApiV1ProfileRapidSignupCreateRequest {
    rapidOnboarding: RapidOnboarding;
}

export interface ApiV1PromotionsListRequest {
    limit?: number;
    offset?: number;
}

export interface ApiV1PromotionsRetrieveRequest {
    id: string;
}

export interface ApiV1ShelfListRequest {
    lastChecked: number;
}

export interface ApiV1SitesContainersTagsRetrieveRequest {
    id: string;
}

export interface ApiV1SitesListRequest {
    latitude: string;
    longitude: string;
    promotionIds: string;
    radius: number;
    containerTypeCategoryId?: string;
    containerTypeId?: string;
    limit?: number;
    offset?: number;
}

export interface ApiV1SitesRetrieveRequest {
    id: string;
}

export interface ApiV1TagProvisionCreateRequest {
    tag: Tag;
}

export interface ApiV1TagProvisionRetrieveRequest {
    id: string;
}

export interface ApiV1TestEmailHtmlRetrieveRequest {
    _3?: string;
    _4?: string;
    _6?: string;
    username?: string;
}

export interface ApiV1UserChargeCreateRequest {
    username: Username;
}

export interface ApiV1UserReferralCreateRequest {
    referral: Referral;
}

export interface ApiV1VendorsExportInventoriesRetrieveRequest {
    month: string;
    year: string;
}

export interface ApiV1VendorsInventoriesRetrieveRequest {
    month: string;
    year: string;
}

export interface ApiV1VendorsInventoriesRetrieve2Request {
    id: string;
    month: string;
    year: string;
}

export interface ApiV1VendorsPromotionsRetrieveRequest {
    month: string;
    year: string;
}

export interface ApiV1VendorsPromotionsRetrieve2Request {
    id: string;
    month: string;
    year: string;
}

export interface ApiV1VendorsSitesRetrieveRequest {
    id: string;
}

export interface ApiV1VendorsTransactionsListRequest {
    month: string;
    year: string;
}

export interface ApiV1VendorsTransactionsList2Request {
    id: string;
    month: string;
    year: string;
}

/**
 * 
 */
export class ApiApi extends runtime.BaseAPI {

    /**
     */
    async apiV1AddressesListRaw(requestParameters: ApiV1AddressesListRequest): Promise<runtime.ApiResponse<PaginatedAddressList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAddressListFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AddressesList(requestParameters: ApiV1AddressesListRequest): Promise<PaginatedAddressList> {
        const response = await this.apiV1AddressesListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1AddressesRetrieveRaw(requestParameters: ApiV1AddressesRetrieveRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1AddressesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     */
    async apiV1AddressesRetrieve(requestParameters: ApiV1AddressesRetrieveRequest): Promise<Address> {
        const response = await this.apiV1AddressesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1BalanceListRaw(): Promise<runtime.ApiResponse<Array<UserBalance>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/balance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBalanceFromJSON));
    }

    /**
     */
    async apiV1BalanceList(): Promise<Array<UserBalance>> {
        const response = await this.apiV1BalanceListRaw();
        return await response.value();
    }

    /**
     * Checks if the current version of the app is out of date
     */
    async apiV1CheckUpdateRetrieveRaw(requestParameters: ApiV1CheckUpdateRetrieveRequest): Promise<runtime.ApiResponse<CheckUpdate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.appVersion !== undefined && requestParameters.appVersion !== null) {
            headerParameters['app-version'] = String(requestParameters.appVersion);
        }

        if (requestParameters.platform !== undefined && requestParameters.platform !== null) {
            headerParameters['platform'] = String(requestParameters.platform);
        }

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/check_update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckUpdateFromJSON(jsonValue));
    }

    /**
     * Checks if the current version of the app is out of date
     */
    async apiV1CheckUpdateRetrieve(requestParameters: ApiV1CheckUpdateRetrieveRequest): Promise<CheckUpdate> {
        const response = await this.apiV1CheckUpdateRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContainerIssueCreateRaw(requestParameters: ApiV1ContainerIssueCreateRequest): Promise<runtime.ApiResponse<IssuedShelfItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container/issue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueToJSON(requestParameters.issue),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IssuedShelfItemFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContainerIssueCreate(requestParameters: ApiV1ContainerIssueCreateRequest): Promise<IssuedShelfItem> {
        const response = await this.apiV1ContainerIssueCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContainerReturnCreateRaw(requestParameters: ApiV1ContainerReturnCreateRequest): Promise<runtime.ApiResponse<ReturnItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container/return`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReturnToJSON(requestParameters._return),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReturnItemFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContainerReturnCreate(requestParameters: ApiV1ContainerReturnCreateRequest): Promise<ReturnItem> {
        const response = await this.apiV1ContainerReturnCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContainerSwapCreateRaw(requestParameters: ApiV1ContainerSwapCreateRequest): Promise<runtime.ApiResponse<IssuedShelfItem>> {
        if (requestParameters.swap === null || requestParameters.swap === undefined) {
            throw new runtime.RequiredError('swap','Required parameter requestParameters.swap was null or undefined when calling apiV1ContainerSwapCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container/swap`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwapToJSON(requestParameters.swap),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IssuedShelfItemFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContainerSwapCreate(requestParameters: ApiV1ContainerSwapCreateRequest): Promise<IssuedShelfItem> {
        const response = await this.apiV1ContainerSwapCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContainerTypeCategoriesListRaw(): Promise<runtime.ApiResponse<Array<ContainerCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container_type_categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContainerCategoryFromJSON));
    }

    /**
     */
    async apiV1ContainerTypeCategoriesList(): Promise<Array<ContainerCategory>> {
        const response = await this.apiV1ContainerTypeCategoriesListRaw();
        return await response.value();
    }

    /**
     */
    async apiV1ContainerTypeCategoriesRetrieveRaw(requestParameters: ApiV1ContainerTypeCategoriesRetrieveRequest): Promise<runtime.ApiResponse<ContainerCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ContainerTypeCategoriesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container_type_categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerCategoryFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContainerTypeCategoriesRetrieve(requestParameters: ApiV1ContainerTypeCategoriesRetrieveRequest): Promise<ContainerCategory> {
        const response = await this.apiV1ContainerTypeCategoriesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ContainerTypesListRaw(): Promise<runtime.ApiResponse<Array<ContainerType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContainerTypeFromJSON));
    }

    /**
     */
    async apiV1ContainerTypesList(): Promise<Array<ContainerType>> {
        const response = await this.apiV1ContainerTypesListRaw();
        return await response.value();
    }

    /**
     */
    async apiV1ContainerTypesRetrieveRaw(requestParameters: ApiV1ContainerTypesRetrieveRequest): Promise<runtime.ApiResponse<ContainerType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ContainerTypesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/container_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerTypeFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ContainerTypesRetrieve(requestParameters: ApiV1ContainerTypesRetrieveRequest): Promise<ContainerType> {
        const response = await this.apiV1ContainerTypesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a test notification to the users registered devices
     */
    async apiV1DevDevicesNotificationCreateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/devices/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a test notification to the users registered devices
     */
    async apiV1DevDevicesNotificationCreate(): Promise<void> {
        await this.apiV1DevDevicesNotificationCreateRaw();
    }

    /**
     * ages the shelf by a set number of days
     */
    async apiV1DevShelfAgeMyShelfRetrieveRaw(requestParameters: ApiV1DevShelfAgeMyShelfRetrieveRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.days !== undefined) {
            queryParameters['days'] = requestParameters.days;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/shelf/age_my_shelf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ages the shelf by a set number of days
     */
    async apiV1DevShelfAgeMyShelfRetrieve(requestParameters: ApiV1DevShelfAgeMyShelfRetrieveRequest): Promise<void> {
        await this.apiV1DevShelfAgeMyShelfRetrieveRaw(requestParameters);
    }

    /**
     * creates a bunch of random shelf items at a set number of days before today. Intervals are [0,3,8,15,22] days
     */
    async apiV1DevShelfCreateShelfItemsRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/shelf/create_shelf_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * creates a bunch of random shelf items at a set number of days before today. Intervals are [0,3,8,15,22] days
     */
    async apiV1DevShelfCreateShelfItemsRetrieve(): Promise<void> {
        await this.apiV1DevShelfCreateShelfItemsRetrieveRaw();
    }

    /**
     * Creates creates a vendor with the site you set. Then adds bunch of container movements and replacements to the site
     */
    async apiV1DevShelfCreateVendorMockDataRetrieveRaw(requestParameters: ApiV1DevShelfCreateVendorMockDataRetrieveRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/shelf/create_vendor_mock_data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates creates a vendor with the site you set. Then adds bunch of container movements and replacements to the site
     */
    async apiV1DevShelfCreateVendorMockDataRetrieve(requestParameters: ApiV1DevShelfCreateVendorMockDataRetrieveRequest): Promise<void> {
        await this.apiV1DevShelfCreateVendorMockDataRetrieveRaw(requestParameters);
    }

    /**
     * Deletes all your current shelf items. Useful if you hit the create endpoint too many times
     */
    async apiV1DevShelfDeleteShelfItemsRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/shelf/delete_shelf_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes all your current shelf items. Useful if you hit the create endpoint too many times
     */
    async apiV1DevShelfDeleteShelfItemsRetrieve(): Promise<void> {
        await this.apiV1DevShelfDeleteShelfItemsRetrieveRaw();
    }

    /**
     * creates a user referral reward for your user
     */
    async apiV1DevShelfRewardYoSelfRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dev/shelf/reward_yo_self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * creates a user referral reward for your user
     */
    async apiV1DevShelfRewardYoSelfRetrieve(): Promise<void> {
        await this.apiV1DevShelfRewardYoSelfRetrieveRaw();
    }

    /**
     * Deregisters the users device for notifications from FCM
     */
    async apiV1DevicesDeregisterDestroyRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/devices/deregister`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deregisters the users device for notifications from FCM
     */
    async apiV1DevicesDeregisterDestroy(): Promise<void> {
        await this.apiV1DevicesDeregisterDestroyRaw();
    }

    /**
     * Registers the users device for notifications from FCM
     */
    async apiV1DevicesRegisterCreateRaw(requestParameters: ApiV1DevicesRegisterCreateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceRegistration === null || requestParameters.deviceRegistration === undefined) {
            throw new runtime.RequiredError('deviceRegistration','Required parameter requestParameters.deviceRegistration was null or undefined when calling apiV1DevicesRegisterCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/devices/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceRegistrationToJSON(requestParameters.deviceRegistration),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Registers the users device for notifications from FCM
     */
    async apiV1DevicesRegisterCreate(requestParameters: ApiV1DevicesRegisterCreateRequest): Promise<void> {
        await this.apiV1DevicesRegisterCreateRaw(requestParameters);
    }

    /**
     * Exports invoices for the desired month to csv format
     */
    async apiV1InvoicesAccountsExportRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/invoices/accounts/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Exports invoices for the desired month to csv format
     */
    async apiV1InvoicesAccountsExportRetrieve(): Promise<void> {
        await this.apiV1InvoicesAccountsExportRetrieveRaw();
    }

    /**
     * Exports invoices for the desired month to csv format
     */
    async apiV1InvoicesContainersExportRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/invoices/containers/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Exports invoices for the desired month to csv format
     */
    async apiV1InvoicesContainersExportRetrieve(): Promise<void> {
        await this.apiV1InvoicesContainersExportRetrieveRaw();
    }

    /**
     * Create a stripe setupintent to allow a payment method to be added to a customer for future charges.
     */
    async apiV1PaymentMethodsAddCreateRaw(): Promise<runtime.ApiResponse<StripePaymentIntent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/payment_methods/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StripePaymentIntentFromJSON(jsonValue));
    }

    /**
     * Create a stripe setupintent to allow a payment method to be added to a customer for future charges.
     */
    async apiV1PaymentMethodsAddCreate(): Promise<StripePaymentIntent> {
        const response = await this.apiV1PaymentMethodsAddCreateRaw();
        return await response.value();
    }

    /**
     * Returns the default (or first) stripe payment method saved for the user
     */
    async apiV1PaymentMethodsListRetrieveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/payment_methods/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns the default (or first) stripe payment method saved for the user
     */
    async apiV1PaymentMethodsListRetrieve(): Promise<void> {
        await this.apiV1PaymentMethodsListRetrieveRaw();
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMePartialUpdateRaw(requestParameters: ApiV1ProfileMePartialUpdateRequest): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserToJSON(requestParameters.patchedUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMePartialUpdate(requestParameters: ApiV1ProfileMePartialUpdateRequest): Promise<User> {
        const response = await this.apiV1ProfileMePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMeRetrieveRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMeRetrieve(): Promise<User> {
        const response = await this.apiV1ProfileMeRetrieveRaw();
        return await response.value();
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMeUpdateRaw(requestParameters: ApiV1ProfileMeUpdateRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling apiV1ProfileMeUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Uses access_token retrieve the users profile
     */
    async apiV1ProfileMeUpdate(requestParameters: ApiV1ProfileMeUpdateRequest): Promise<User> {
        const response = await this.apiV1ProfileMeUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1ProfileRapidSignupCreateRaw(requestParameters: ApiV1ProfileRapidSignupCreateRequest): Promise<runtime.ApiResponse<RapidOnboarding>> {
        if (requestParameters.rapidOnboarding === null || requestParameters.rapidOnboarding === undefined) {
            throw new runtime.RequiredError('rapidOnboarding','Required parameter requestParameters.rapidOnboarding was null or undefined when calling apiV1ProfileRapidSignupCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/rapid-signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapidOnboardingToJSON(requestParameters.rapidOnboarding),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RapidOnboardingFromJSON(jsonValue));
    }

    /**
     */
    async apiV1ProfileRapidSignupCreate(requestParameters: ApiV1ProfileRapidSignupCreateRequest): Promise<RapidOnboarding> {
        const response = await this.apiV1ProfileRapidSignupCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1PromotionsListRaw(requestParameters: ApiV1PromotionsListRequest): Promise<runtime.ApiResponse<PaginatedPromotionList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/promotions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPromotionListFromJSON(jsonValue));
    }

    /**
     */
    async apiV1PromotionsList(requestParameters: ApiV1PromotionsListRequest): Promise<PaginatedPromotionList> {
        const response = await this.apiV1PromotionsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1PromotionsRetrieveRaw(requestParameters: ApiV1PromotionsRetrieveRequest): Promise<runtime.ApiResponse<Promotion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1PromotionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/promotions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PromotionFromJSON(jsonValue));
    }

    /**
     */
    async apiV1PromotionsRetrieve(requestParameters: ApiV1PromotionsRetrieveRequest): Promise<Promotion> {
        const response = await this.apiV1PromotionsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1RewardsListRaw(): Promise<runtime.ApiResponse<Array<RewardStatus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/rewards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RewardStatusFromJSON));
    }

    /**
     */
    async apiV1RewardsList(): Promise<Array<RewardStatus>> {
        const response = await this.apiV1RewardsListRaw();
        return await response.value();
    }

    /**
     */
    async apiV1ShelfListRaw(requestParameters: ApiV1ShelfListRequest): Promise<runtime.ApiResponse<Array<Shelf>>> {
        if (requestParameters.lastChecked === null || requestParameters.lastChecked === undefined) {
            throw new runtime.RequiredError('lastChecked','Required parameter requestParameters.lastChecked was null or undefined when calling apiV1ShelfList.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastChecked !== undefined) {
            queryParameters['last_checked'] = requestParameters.lastChecked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/shelf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShelfFromJSON));
    }

    /**
     */
    async apiV1ShelfList(requestParameters: ApiV1ShelfListRequest): Promise<Array<Shelf>> {
        const response = await this.apiV1ShelfListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1SitesContainersTagsRetrieveRaw(requestParameters: ApiV1SitesContainersTagsRetrieveRequest): Promise<runtime.ApiResponse<SiteContainersByTag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1SitesContainersTagsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/sites/containers/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteContainersByTagFromJSON(jsonValue));
    }

    /**
     */
    async apiV1SitesContainersTagsRetrieve(requestParameters: ApiV1SitesContainersTagsRetrieveRequest): Promise<SiteContainersByTag> {
        const response = await this.apiV1SitesContainersTagsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1SitesListRaw(requestParameters: ApiV1SitesListRequest): Promise<runtime.ApiResponse<PaginatedSiteList>> {
        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling apiV1SitesList.');
        }

        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling apiV1SitesList.');
        }

        if (requestParameters.promotionIds === null || requestParameters.promotionIds === undefined) {
            throw new runtime.RequiredError('promotionIds','Required parameter requestParameters.promotionIds was null or undefined when calling apiV1SitesList.');
        }

        if (requestParameters.radius === null || requestParameters.radius === undefined) {
            throw new runtime.RequiredError('radius','Required parameter requestParameters.radius was null or undefined when calling apiV1SitesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.containerTypeCategoryId !== undefined) {
            queryParameters['container_type_category_id'] = requestParameters.containerTypeCategoryId;
        }

        if (requestParameters.containerTypeId !== undefined) {
            queryParameters['container_type_id'] = requestParameters.containerTypeId;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.promotionIds !== undefined) {
            queryParameters['promotion_ids'] = requestParameters.promotionIds;
        }

        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSiteListFromJSON(jsonValue));
    }

    /**
     */
    async apiV1SitesList(requestParameters: ApiV1SitesListRequest): Promise<PaginatedSiteList> {
        const response = await this.apiV1SitesListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1SitesRetrieveRaw(requestParameters: ApiV1SitesRetrieveRequest): Promise<runtime.ApiResponse<Site>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1SitesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/sites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }

    /**
     */
    async apiV1SitesRetrieve(requestParameters: ApiV1SitesRetrieveRequest): Promise<Site> {
        const response = await this.apiV1SitesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1TagProvisionCreateRaw(requestParameters: ApiV1TagProvisionCreateRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.tag === null || requestParameters.tag === undefined) {
            throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling apiV1TagProvisionCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tag/provision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async apiV1TagProvisionCreate(requestParameters: ApiV1TagProvisionCreateRequest): Promise<Tag> {
        const response = await this.apiV1TagProvisionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1TagProvisionLastAllocatedRetrieveRaw(): Promise<runtime.ApiResponse<Tag>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tag/provision/last_allocated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async apiV1TagProvisionLastAllocatedRetrieve(): Promise<Tag> {
        const response = await this.apiV1TagProvisionLastAllocatedRetrieveRaw();
        return await response.value();
    }

    /**
     */
    async apiV1TagProvisionRetrieveRaw(requestParameters: ApiV1TagProvisionRetrieveRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1TagProvisionRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tag/provision/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async apiV1TagProvisionRetrieve(requestParameters: ApiV1TagProvisionRetrieveRequest): Promise<Tag> {
        const response = await this.apiV1TagProvisionRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1TestEmailHtmlRetrieveRaw(requestParameters: ApiV1TestEmailHtmlRetrieveRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters._3 !== undefined) {
            queryParameters['3'] = requestParameters._3;
        }

        if (requestParameters._4 !== undefined) {
            queryParameters['4'] = requestParameters._4;
        }

        if (requestParameters._6 !== undefined) {
            queryParameters['6'] = requestParameters._6;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/test_email_html`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1TestEmailHtmlRetrieve(requestParameters: ApiV1TestEmailHtmlRetrieveRequest): Promise<void> {
        await this.apiV1TestEmailHtmlRetrieveRaw(requestParameters);
    }

    /**
     */
    async apiV1UserChargeCreateRaw(requestParameters: ApiV1UserChargeCreateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling apiV1UserChargeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/charge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsernameToJSON(requestParameters.username),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1UserChargeCreate(requestParameters: ApiV1UserChargeCreateRequest): Promise<void> {
        await this.apiV1UserChargeCreateRaw(requestParameters);
    }

    /**
     */
    async apiV1UserReferralCreateRaw(requestParameters: ApiV1UserReferralCreateRequest): Promise<runtime.ApiResponse<Reward>> {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling apiV1UserReferralCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user_referral`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RewardFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserReferralCreate(requestParameters: ApiV1UserReferralCreateRequest): Promise<Reward> {
        const response = await this.apiV1UserReferralCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves impact metrics for the user, systemwide and referred friends
     */
    async apiV1UsersImpactRetrieveRaw(): Promise<runtime.ApiResponse<UserImpact>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/impact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserImpactFromJSON(jsonValue));
    }

    /**
     * Retrieves impact metrics for the user, systemwide and referred friends
     */
    async apiV1UsersImpactRetrieve(): Promise<UserImpact> {
        const response = await this.apiV1UsersImpactRetrieveRaw();
        return await response.value();
    }

    /**
     * Retrieves container transactions vendor
     */
    async apiV1VendorsExportInventoriesRetrieveRaw(requestParameters: ApiV1VendorsExportInventoriesRetrieveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsExportInventoriesRetrieve.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsExportInventoriesRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/export/inventories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieves container transactions vendor
     */
    async apiV1VendorsExportInventoriesRetrieve(requestParameters: ApiV1VendorsExportInventoriesRetrieveRequest): Promise<void> {
        await this.apiV1VendorsExportInventoriesRetrieveRaw(requestParameters);
    }

    /**
     * Retrieves impact metrics for the vendor and systemwide
     */
    async apiV1VendorsImpactRetrieveRaw(): Promise<runtime.ApiResponse<VendorImpact>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/impact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorImpactFromJSON(jsonValue));
    }

    /**
     * Retrieves impact metrics for the vendor and systemwide
     */
    async apiV1VendorsImpactRetrieve(): Promise<VendorImpact> {
        const response = await this.apiV1VendorsImpactRetrieveRaw();
        return await response.value();
    }

    /**
     */
    async apiV1VendorsInventoriesRetrieveRaw(requestParameters: ApiV1VendorsInventoriesRetrieveRequest): Promise<runtime.ApiResponse<VendorInventories>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsInventoriesRetrieve.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsInventoriesRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/inventories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorInventoriesFromJSON(jsonValue));
    }

    /**
     */
    async apiV1VendorsInventoriesRetrieve(requestParameters: ApiV1VendorsInventoriesRetrieveRequest): Promise<VendorInventories> {
        const response = await this.apiV1VendorsInventoriesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1VendorsInventoriesRetrieve2Raw(requestParameters: ApiV1VendorsInventoriesRetrieve2Request): Promise<runtime.ApiResponse<VendorInventories>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1VendorsInventoriesRetrieve2.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsInventoriesRetrieve2.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsInventoriesRetrieve2.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/inventories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorInventoriesFromJSON(jsonValue));
    }

    /**
     */
    async apiV1VendorsInventoriesRetrieve2(requestParameters: ApiV1VendorsInventoriesRetrieve2Request): Promise<VendorInventories> {
        const response = await this.apiV1VendorsInventoriesRetrieve2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves promotions and reward transactions for the vendor
     */
    async apiV1VendorsPromotionsRetrieveRaw(requestParameters: ApiV1VendorsPromotionsRetrieveRequest): Promise<runtime.ApiResponse<VendorPromotions>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsPromotionsRetrieve.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsPromotionsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/promotions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorPromotionsFromJSON(jsonValue));
    }

    /**
     * Retrieves promotions and reward transactions for the vendor
     */
    async apiV1VendorsPromotionsRetrieve(requestParameters: ApiV1VendorsPromotionsRetrieveRequest): Promise<VendorPromotions> {
        const response = await this.apiV1VendorsPromotionsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves promotions and reward transactions for the vendor
     */
    async apiV1VendorsPromotionsRetrieve2Raw(requestParameters: ApiV1VendorsPromotionsRetrieve2Request): Promise<runtime.ApiResponse<VendorPromotions>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1VendorsPromotionsRetrieve2.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsPromotionsRetrieve2.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsPromotionsRetrieve2.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/promotions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorPromotionsFromJSON(jsonValue));
    }

    /**
     * Retrieves promotions and reward transactions for the vendor
     */
    async apiV1VendorsPromotionsRetrieve2(requestParameters: ApiV1VendorsPromotionsRetrieve2Request): Promise<VendorPromotions> {
        const response = await this.apiV1VendorsPromotionsRetrieve2Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiV1VendorsSitesListRaw(): Promise<runtime.ApiResponse<Array<Vendor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VendorFromJSON));
    }

    /**
     */
    async apiV1VendorsSitesList(): Promise<Array<Vendor>> {
        const response = await this.apiV1VendorsSitesListRaw();
        return await response.value();
    }

    /**
     */
    async apiV1VendorsSitesRetrieveRaw(requestParameters: ApiV1VendorsSitesRetrieveRequest): Promise<runtime.ApiResponse<Vendor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1VendorsSitesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/sites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorFromJSON(jsonValue));
    }

    /**
     */
    async apiV1VendorsSitesRetrieve(requestParameters: ApiV1VendorsSitesRetrieveRequest): Promise<Vendor> {
        const response = await this.apiV1VendorsSitesRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves container transactions by vendor
     */
    async apiV1VendorsTransactionsListRaw(requestParameters: ApiV1VendorsTransactionsListRequest): Promise<runtime.ApiResponse<Array<ContainerMovementLedger>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsTransactionsList.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsTransactionsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContainerMovementLedgerFromJSON));
    }

    /**
     * Retrieves container transactions by vendor
     */
    async apiV1VendorsTransactionsList(requestParameters: ApiV1VendorsTransactionsListRequest): Promise<Array<ContainerMovementLedger>> {
        const response = await this.apiV1VendorsTransactionsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves container transactions by vendor
     */
    async apiV1VendorsTransactionsList2Raw(requestParameters: ApiV1VendorsTransactionsList2Request): Promise<runtime.ApiResponse<Array<ContainerMovementLedger>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1VendorsTransactionsList2.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling apiV1VendorsTransactionsList2.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling apiV1VendorsTransactionsList2.');
        }

        const queryParameters: any = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/vendors/transactions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContainerMovementLedgerFromJSON));
    }

    /**
     * Retrieves container transactions by vendor
     */
    async apiV1VendorsTransactionsList2(requestParameters: ApiV1VendorsTransactionsList2Request): Promise<Array<ContainerMovementLedger>> {
        const response = await this.apiV1VendorsTransactionsList2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Webhook for recieving stripe events. Currently supports payment_intent.succeeded/failed/canceled Stripe attempts to deliver your webhooks for up to three days with an exponential back off. Stripe webhooks - https://stripe.com/docs/webhooks/ Stripe will keep sending webhook events until it gets a 20X stat
     */
    async apiV1WebhookPaymentMethodsCreateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/webhook/payment_methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook for recieving stripe events. Currently supports payment_intent.succeeded/failed/canceled Stripe attempts to deliver your webhooks for up to three days with an exponential back off. Stripe webhooks - https://stripe.com/docs/webhooks/ Stripe will keep sending webhook events until it gets a 20X stat
     */
    async apiV1WebhookPaymentMethodsCreate(): Promise<void> {
        await this.apiV1WebhookPaymentMethodsCreateRaw();
    }

    /**
     * Webhook for recieving stripe events. Currently supports payment_intent.succeeded/failed/canceled Stripe attempts to deliver your webhooks for up to three days with an exponential back off. Stripe webhooks - https://stripe.com/docs/webhooks/ Stripe will keep sending webhook events until it gets a 20X stat
     */
    async apiV1WebhookPaymentsCreateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/webhook/payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook for recieving stripe events. Currently supports payment_intent.succeeded/failed/canceled Stripe attempts to deliver your webhooks for up to three days with an exponential back off. Stripe webhooks - https://stripe.com/docs/webhooks/ Stripe will keep sending webhook events until it gets a 20X stat
     */
    async apiV1WebhookPaymentsCreate(): Promise<void> {
        await this.apiV1WebhookPaymentsCreateRaw();
    }

}
