/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImpactMetrics,
    ImpactMetricsFromJSON,
    ImpactMetricsFromJSONTyped,
    ImpactMetricsToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorImpact
 */
export interface VendorImpact {
    /**
     * 
     * @type {ImpactMetrics}
     * @memberof VendorImpact
     */
    vendorImpact: ImpactMetrics;
    /**
     * 
     * @type {ImpactMetrics}
     * @memberof VendorImpact
     */
    systemImpact: ImpactMetrics;
}

export function VendorImpactFromJSON(json: any): VendorImpact {
    return VendorImpactFromJSONTyped(json, false);
}

export function VendorImpactFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorImpact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vendorImpact': ImpactMetricsFromJSON(json['vendor_impact']),
        'systemImpact': ImpactMetricsFromJSON(json['system_impact']),
    };
}

export function VendorImpactToJSON(value?: VendorImpact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor_impact': ImpactMetricsToJSON(value.vendorImpact),
        'system_impact': ImpactMetricsToJSON(value.systemImpact),
    };
}


