/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Username
 */
export interface Username {
    /**
     * 
     * @type {string}
     * @memberof Username
     */
    username: string;
}

export function UsernameFromJSON(json: any): Username {
    return UsernameFromJSONTyped(json, false);
}

export function UsernameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Username {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
    };
}

export function UsernameToJSON(value?: Username | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
    };
}


