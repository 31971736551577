/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Site,
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './';

/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    businessPhone: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    primaryContact: string;
    /**
     * 
     * @type {Array<Site>}
     * @memberof Vendor
     */
    readonly sites: Array<Site>;
}

export function VendorFromJSON(json: any): Vendor {
    return VendorFromJSONTyped(json, false);
}

export function VendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vendor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'businessPhone': json['business_phone'],
        'primaryContact': json['primary_contact'],
        'sites': ((json['sites'] as Array<any>).map(SiteFromJSON)),
    };
}

export function VendorToJSON(value?: Vendor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'business_phone': value.businessPhone,
        'primary_contact': value.primaryContact,
    };
}


