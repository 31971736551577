import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Card: ComponentMultiStyleConfig = {
  parts: ['Container', 'Card', 'MetricNumber', 'Metric', 'Content', 'ImageContainer', 'Image'],
  baseStyle: {
    Container: {
      marginBottom: '48px',
      display: 'inline-block',
    },
    Card: {
      display: 'inline-block',
      position: 'relative',
      borderRadius: '16px',
      paddingTop: '8px',
      paddingBottom: '16px', //'64px',
      paddingLeft: '16px',
      paddingRight: '16px',
      bgColor: 'brand.green',
      color: 'brand.white',
      height: '100%',
      width: '100%',
      minWidth: '164px',
    },
    MetricNumber: {
      fontSize: '48px',
      lineHeight: '1.2',
    },
    Metric: {
      marginLeft: '4px',
      fontSize: '16px',
      fontWeight: '600',
    },
    Content: {
      maxWidth: '132px',
    },
    ImageContainer: {
      width: '132px',
      height: 'auto',
      margin: '0 auto',
    },
    Image: {
      objectFit: 'contain',
    },
  },
  variants: {
    grey: {
      Card: {
        bgColor: 'brand.extralightgrey',
        color: 'brand.black',
      },
    },
    green: { Card: { bgColor: 'brand.green' } },
    blue: { Card: { bgColor: 'brand.blue' } },
    yellow: { Card: { bgColor: 'brand.yellow', color: 'brand.black' } },
  },
  sizes: {
    sm: { Card: {} },
    md: {
      Card: { paddingTop: '16px' },
      Content: {
        position: 'absolute',
        bottom: '16px',
        left: '16px',
        // paddingTop: '16px',
      },
    },
  },
  defaultProps: {
    variant: 'grey',
    size: 'sm',
  },
};
