import { ComponentStyleConfig } from "@chakra-ui/react";

export const NextPrevFilter: ComponentStyleConfig = {
  parts: ["FilterIconButton", "FilterText"],
  baseStyle: {
    FilterIconButton: {
      bgColor: "brand.lightblue",
      fontSize: "16px",
      color: "brand.blue",
      transition: "background-color .2s",
      ":hover": {
        bgColor: "brand.lightblue",
        color: "brand.darkblue",
      },
      ":active, &[data-active]": {
        bgColor: "brand.lightblue",
      },
      ":disabled, &[data-disabled]": {
        opacity: "1",
        bgColor: "brand.lightblue",
        color: "brand.darkgrey",
      },
    },
    FilterText: {
      width: "138px",
      textAlign: "center",
      bgColor: "brand.lightblue",
      paddingTop: "8px",
      paddingBottom: "8px",
      position: "relative",
      fontWeight: "600",
      fontSize: "16px",
      color: "brand.blue",

      ":focus, &[data-focus]": {
        background: "brand.lightblue",
      },
    },
  },
};
