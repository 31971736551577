/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripePaymentIntent
 */
export interface StripePaymentIntent {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntent
     */
    setupIntent: string;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntent
     */
    stripePublishableKey: string;
}

export function StripePaymentIntentFromJSON(json: any): StripePaymentIntent {
    return StripePaymentIntentFromJSONTyped(json, false);
}

export function StripePaymentIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePaymentIntent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setupIntent': json['setup_intent'],
        'stripePublishableKey': json['stripe_publishable_key'],
    };
}

export function StripePaymentIntentToJSON(value?: StripePaymentIntent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setup_intent': value.setupIntent,
        'stripe_publishable_key': value.stripePublishableKey,
    };
}


