/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImpactMetrics,
    ImpactMetricsFromJSON,
    ImpactMetricsFromJSONTyped,
    ImpactMetricsToJSON,
    ReferralImpactMetrics,
    ReferralImpactMetricsFromJSON,
    ReferralImpactMetricsFromJSONTyped,
    ReferralImpactMetricsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserImpact
 */
export interface UserImpact {
    /**
     * 
     * @type {ImpactMetrics}
     * @memberof UserImpact
     */
    personalImpact: ImpactMetrics;
    /**
     * 
     * @type {ImpactMetrics}
     * @memberof UserImpact
     */
    systemImpact: ImpactMetrics;
    /**
     * 
     * @type {ReferralImpactMetrics}
     * @memberof UserImpact
     */
    referralImpact: ReferralImpactMetrics;
}

export function UserImpactFromJSON(json: any): UserImpact {
    return UserImpactFromJSONTyped(json, false);
}

export function UserImpactFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserImpact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalImpact': ImpactMetricsFromJSON(json['personal_impact']),
        'systemImpact': ImpactMetricsFromJSON(json['system_impact']),
        'referralImpact': ReferralImpactMetricsFromJSON(json['referral_impact']),
    };
}

export function UserImpactToJSON(value?: UserImpact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personal_impact': ImpactMetricsToJSON(value.personalImpact),
        'system_impact': ImpactMetricsToJSON(value.systemImpact),
        'referral_impact': ReferralImpactMetricsToJSON(value.referralImpact),
    };
}


