/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TierEnum {
    Utility = 'UTILITY',
    Standard = 'STANDARD',
    Premium = 'PREMIUM'
}

export function TierEnumFromJSON(json: any): TierEnum {
    return TierEnumFromJSONTyped(json, false);
}

export function TierEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TierEnum {
    return json as TierEnum;
}

export function TierEnumToJSON(value?: TierEnum | null): any {
    return value as any;
}

