/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BalanceLedgerEventTypeEnum,
    BalanceLedgerEventTypeEnumFromJSON,
    BalanceLedgerEventTypeEnumFromJSONTyped,
    BalanceLedgerEventTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface BalanceLedger
 */
export interface BalanceLedger {
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly title: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly subtitle: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly amount: string;
    /**
     * 
     * @type {BalanceLedgerEventTypeEnum}
     * @memberof BalanceLedger
     */
    eventType: BalanceLedgerEventTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BalanceLedger
     */
    effectiveFrom: Date;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly container: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly borrowedFrom: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceLedger
     */
    readonly borrowedTime: string;
}

export function BalanceLedgerFromJSON(json: any): BalanceLedger {
    return BalanceLedgerFromJSONTyped(json, false);
}

export function BalanceLedgerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceLedger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'subtitle': json['subtitle'],
        'amount': json['amount'],
        'eventType': BalanceLedgerEventTypeEnumFromJSON(json['event_type']),
        'effectiveFrom': (new Date(json['effective_from'])),
        'container': json['container'],
        'borrowedFrom': json['borrowed_from'],
        'borrowedTime': json['borrowed_time'],
    };
}

export function BalanceLedgerToJSON(value?: BalanceLedger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_type': BalanceLedgerEventTypeEnumToJSON(value.eventType),
        'effective_from': (value.effectiveFrom.toISOString()),
    };
}


