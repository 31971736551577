/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckUpdate
 */
export interface CheckUpdate {
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate
     */
    url: string;
}

export function CheckUpdateFromJSON(json: any): CheckUpdate {
    return CheckUpdateFromJSONTyped(json, false);
}

export function CheckUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'message': json['message'],
        'url': json['url'],
    };
}

export function CheckUpdateToJSON(value?: CheckUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
        'url': value.url,
    };
}


