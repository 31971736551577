/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Milestone,
    MilestoneFromJSON,
    MilestoneFromJSONTyped,
    MilestoneToJSON,
    Reward,
    RewardFromJSON,
    RewardFromJSONTyped,
    RewardToJSON,
    Shelf,
    ShelfFromJSON,
    ShelfFromJSONTyped,
    ShelfToJSON,
} from './';

/**
 * 
 * @export
 * @interface IssuedShelfItem
 */
export interface IssuedShelfItem {
    /**
     * 
     * @type {Shelf}
     * @memberof IssuedShelfItem
     */
    shelfItem?: Shelf;
    /**
     * 
     * @type {Array<Shelf>}
     * @memberof IssuedShelfItem
     */
    shelfItems?: Array<Shelf>;
    /**
     * 
     * @type {Array<Milestone>}
     * @memberof IssuedShelfItem
     */
    milestones: Array<Milestone>;
    /**
     * 
     * @type {Array<Reward>}
     * @memberof IssuedShelfItem
     */
    rewardsRedeemed: Array<Reward>;
}

export function IssuedShelfItemFromJSON(json: any): IssuedShelfItem {
    return IssuedShelfItemFromJSONTyped(json, false);
}

export function IssuedShelfItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuedShelfItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shelfItem': !exists(json, 'shelf_item') ? undefined : ShelfFromJSON(json['shelf_item']),
        'shelfItems': !exists(json, 'shelf_items') ? undefined : ((json['shelf_items'] as Array<any>).map(ShelfFromJSON)),
        'milestones': ((json['milestones'] as Array<any>).map(MilestoneFromJSON)),
        'rewardsRedeemed': ((json['rewards_redeemed'] as Array<any>).map(RewardFromJSON)),
    };
}

export function IssuedShelfItemToJSON(value?: IssuedShelfItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shelf_item': ShelfToJSON(value.shelfItem),
        'shelf_items': value.shelfItems === undefined ? undefined : ((value.shelfItems as Array<any>).map(ShelfToJSON)),
        'milestones': ((value.milestones as Array<any>).map(MilestoneToJSON)),
        'rewards_redeemed': ((value.rewardsRedeemed as Array<any>).map(RewardToJSON)),
    };
}


