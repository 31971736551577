/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceRegistration
 */
export interface DeviceRegistration {
    /**
     * 
     * @type {string}
     * @memberof DeviceRegistration
     */
    deviceToken: string;
}

export function DeviceRegistrationFromJSON(json: any): DeviceRegistration {
    return DeviceRegistrationFromJSONTyped(json, false);
}

export function DeviceRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceToken': json['device_token'],
    };
}

export function DeviceRegistrationToJSON(value?: DeviceRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_token': value.deviceToken,
    };
}


