/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    mobileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email: string;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'mobileNumber': json['mobile_number'],
        'email': json['email'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'mobile_number': value.mobileNumber,
        'email': value.email,
    };
}


