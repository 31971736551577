/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NFCTag,
    NFCTagFromJSON,
    NFCTagFromJSONTyped,
    NFCTagToJSON,
    ReturnContainer,
    ReturnContainerFromJSON,
    ReturnContainerFromJSONTyped,
    ReturnContainerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Return
 */
export interface Return {
    /**
     * 
     * @type {Array<ReturnContainer>}
     * @memberof Return
     */
    containers?: Array<ReturnContainer>;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    shelfId?: string;
    /**
     * 
     * @type {NFCTag}
     * @memberof Return
     */
    tag?: NFCTag;
}

export function ReturnFromJSON(json: any): Return {
    return ReturnFromJSONTyped(json, false);
}

export function ReturnFromJSONTyped(json: any, ignoreDiscriminator: boolean): Return {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(ReturnContainerFromJSON)),
        'shelfId': !exists(json, 'shelf_id') ? undefined : json['shelf_id'],
        'tag': !exists(json, 'tag') ? undefined : NFCTagFromJSON(json['tag']),
    };
}

export function ReturnToJSON(value?: Return | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(ReturnContainerToJSON)),
        'shelf_id': value.shelfId,
        'tag': NFCTagToJSON(value.tag),
    };
}


