import { ComponentStyleConfig } from "@chakra-ui/react";

export const Logout: ComponentStyleConfig = {
  parts: ["Button", "MenuList", "MenuItem"],
  baseStyle: {
    Button: {
      bgColor: "brand.green",
      fontSize: "16px",
      color: "brand.white",
      transition: "background-color .2s",
      ":hover": {
        bgColor: "brand.midgreen",
      },
      ":active, &[data-active]": {
        bgColor: "brand.lightgreen",
      },
      ":focus, &[data-focus]": {
        boxShadow: "0 0 0 3px rgba(52,132, 91, .5)",
      },
    },
    MenuList: {},
    MenuItem: {},
  },
};
