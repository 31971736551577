import { ComponentStyleConfig } from "@chakra-ui/react";

export const SkipLink: ComponentStyleConfig = {
  baseStyle: {
    padding: "10px 0",
    margin: "0 auto",
    fontSize: "20px",
    background: "brand.white",
    color: "brand.black",
    display: "block",
    position: "absolute",
    zIndex: "1000",
    transform: "translateY(-500px)",
    ":hover, :focus": {
      transform: "translateY(0px)",
    },
  },
};
