/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpactMetrics
 */
export interface ImpactMetrics {
    /**
     * 
     * @type {number}
     * @memberof ImpactMetrics
     */
    numberOfContainersSaved: number;
    /**
     * 
     * @type {number}
     * @memberof ImpactMetrics
     */
    totalWasteSavedFromLandfill?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpactMetrics
     */
    numberOfLocationVisits: number;
    /**
     * 
     * @type {string}
     * @memberof ImpactMetrics
     */
    impactEquivalentImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactMetrics
     */
    impactEquivalentString?: string;
}

export function ImpactMetricsFromJSON(json: any): ImpactMetrics {
    return ImpactMetricsFromJSONTyped(json, false);
}

export function ImpactMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpactMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfContainersSaved': json['number_of_containers_saved'],
        'totalWasteSavedFromLandfill': !exists(json, 'total_waste_saved_from_landfill') ? undefined : json['total_waste_saved_from_landfill'],
        'numberOfLocationVisits': json['number_of_location_visits'],
        'impactEquivalentImageUrl': !exists(json, 'impact_equivalent_image_url') ? undefined : json['impact_equivalent_image_url'],
        'impactEquivalentString': !exists(json, 'impact_equivalent_string') ? undefined : json['impact_equivalent_string'],
    };
}

export function ImpactMetricsToJSON(value?: ImpactMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_of_containers_saved': value.numberOfContainersSaved,
        'total_waste_saved_from_landfill': value.totalWasteSavedFromLandfill,
        'number_of_location_visits': value.numberOfLocationVisits,
        'impact_equivalent_image_url': value.impactEquivalentImageUrl,
        'impact_equivalent_string': value.impactEquivalentString,
    };
}


