/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Inventory,
    InventoryFromJSON,
    InventoryFromJSONTyped,
    InventoryToJSON,
    SelfSuppliedInventory,
    SelfSuppliedInventoryFromJSON,
    SelfSuppliedInventoryFromJSONTyped,
    SelfSuppliedInventoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorInventories
 */
export interface VendorInventories {
    /**
     * 
     * @type {Array<Inventory>}
     * @memberof VendorInventories
     */
    suppliedBySystemContainers: Array<Inventory>;
    /**
     * 
     * @type {Array<SelfSuppliedInventory>}
     * @memberof VendorInventories
     */
    selfOwnedContainers: Array<SelfSuppliedInventory>;
}

export function VendorInventoriesFromJSON(json: any): VendorInventories {
    return VendorInventoriesFromJSONTyped(json, false);
}

export function VendorInventoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorInventories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suppliedBySystemContainers': ((json['supplied_by_system_containers'] as Array<any>).map(InventoryFromJSON)),
        'selfOwnedContainers': ((json['self_owned_containers'] as Array<any>).map(SelfSuppliedInventoryFromJSON)),
    };
}

export function VendorInventoriesToJSON(value?: VendorInventories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplied_by_system_containers': ((value.suppliedBySystemContainers as Array<any>).map(InventoryToJSON)),
        'self_owned_containers': ((value.selfOwnedContainers as Array<any>).map(SelfSuppliedInventoryToJSON)),
    };
}


