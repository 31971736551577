/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Inventory
 */
export interface Inventory {
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    containerType: string;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    openingBalance: number;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    borrowed: number;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    returned: number;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    netChange: number;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    replaced: number;
    /**
     * 
     * @type {number}
     * @memberof Inventory
     */
    closingBalance: number;
}

export function InventoryFromJSON(json: any): Inventory {
    return InventoryFromJSONTyped(json, false);
}

export function InventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Inventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerType': json['container_type'],
        'openingBalance': json['opening_balance'],
        'borrowed': json['borrowed'],
        'returned': json['returned'],
        'netChange': json['net_change'],
        'replaced': json['replaced'],
        'closingBalance': json['closing_balance'],
    };
}

export function InventoryToJSON(value?: Inventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'container_type': value.containerType,
        'opening_balance': value.openingBalance,
        'borrowed': value.borrowed,
        'returned': value.returned,
        'net_change': value.netChange,
        'replaced': value.replaced,
        'closing_balance': value.closingBalance,
    };
}


