import React, { createContext, useContext, useState, useEffect } from 'react';
import { StripePaymentIntent } from '__generated__';
import { useAPI } from 'utilities/api';

type StripeIntentContextType = {
  stripeIntentData: StripePaymentIntent | undefined;
  isLoading?: boolean;
  error?: Error | undefined;
  fetchStripeIntent: () => void; 
};

const StripeIntentContext = createContext<StripeIntentContextType>({
  stripeIntentData: undefined,
  fetchStripeIntent: () => {},
});

export const useStripeIntent = () => useContext(StripeIntentContext);

export const StripeIntentProvider = ({ children }) => {
  const [stripeIntentData, setStripeIntentData] = useState({
    setupIntent: '',
    stripePublishableKey: ''
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  const api = useAPI();

  const fetchStripeIntentData = async () => {
    try {
      const data = await api.apiV1PaymentMethodsAddCreate();
      setStripeIntentData({
        setupIntent: data.setupIntent,
        stripePublishableKey: data.stripePublishableKey
      });
    } catch (e: any) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStripeIntentData();
  }, []);

  return (
    <StripeIntentContext.Provider value={{ stripeIntentData, isLoading, error, fetchStripeIntent: fetchStripeIntentData }}>
      {children}
    </StripeIntentContext.Provider>
  );
};
