import { ComponentStyleConfig } from '@chakra-ui/react';

export const Content: ComponentStyleConfig = {
  baseStyle: {
    paddingLeft: '32px',
    paddingTop: '56px',
    paddingRight: '56px',
    paddingBottom: '56px',
    bgColor: 'brand.white',
  },
  variants: {
    mobile: {
      paddingLeft: '24px',
      paddingRight: '24px',
      width: '100%',
    },
  },
};
