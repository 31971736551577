import { ComponentStyleConfig } from '@chakra-ui/react';

export const DropdownFilter: ComponentStyleConfig = {
  parts: ['FilterButton', 'FilterItem', 'FilterItemIcon'],
  baseStyle: {
    FilterButton: {
      bgColor: 'brand.lightblue',
      fontSize: '16px',
      color: 'brand.blue',
      marginRight: '16px',
      transition: 'background-color .2s',
      ':hover': {
        bgColor: 'brand.midblue',
      },
      ':active, &[data-active]': {
        bgColor: 'brand.midblue',
      },
    },
    FilterItem: {
      position: 'relative',
      paddingLeft: '42px',
      ':focus, &[data-focus]': {
        background: 'brand.lightblue',
      },
    },
    FilterItemIcon: {
      position: 'absolute',
      top: '4px',
      left: '16px',
      color: 'brand.blue',
    },
  },
};
