/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContainerTypeVersion
 */
export interface ContainerTypeVersion {
    /**
     * 
     * @type {string}
     * @memberof ContainerTypeVersion
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTypeVersion
     */
    estOffsetWeight: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTypeVersion
     */
    replacementCharge: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTypeVersion
     */
    freeLoanPeriod: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTypeVersion
     */
    extraTime?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTypeVersion
     */
    returnAnytime?: boolean;
}

export function ContainerTypeVersionFromJSON(json: any): ContainerTypeVersion {
    return ContainerTypeVersionFromJSONTyped(json, false);
}

export function ContainerTypeVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerTypeVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'estOffsetWeight': json['est_offset_weight'],
        'replacementCharge': json['replacement_charge'],
        'freeLoanPeriod': json['free_loan_period'],
        'extraTime': !exists(json, 'extra_time') ? undefined : json['extra_time'],
        'returnAnytime': !exists(json, 'return_anytime') ? undefined : json['return_anytime'],
    };
}

export function ContainerTypeVersionToJSON(value?: ContainerTypeVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'est_offset_weight': value.estOffsetWeight,
        'replacement_charge': value.replacementCharge,
        'free_loan_period': value.freeLoanPeriod,
        'extra_time': value.extraTime,
        'return_anytime': value.returnAnytime,
    };
}


