export const Table = {
  parts: ['TableContainer', 'Table', 'Th', 'Tr', 'Td', 'TableCaption'],
  baseStyle: {
    TableContainer: {
      overflowX: 'auto',
      width: '100%',
      marginBottom: '48px',
    },
    Table: {
      width: '100%',
      marginBottom: '1rem',
    },
    Th: {
      textTransform: 'none',
      color: 'brand.black',
      fontWeight: '600',
      fontSize: '14px',
      letterSpacing: '0',
    },
    Tr: {
      ':nth-of-type(odd) td': {
        bgColor: 'brand.lightgrey',
      },
    },
    Td: {
      fontSize: '14px',
      ':first-of-type': {
        borderLeftRadius: '8px',
      },
      ':last-of-type': {
        borderRightRadius: '8px',
      },
    },
    TableCaption: {
      bgColor: 'brand.lightgrey',
      borderRadius: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
};
