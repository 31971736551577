/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromotionSummary
 */
export interface PromotionSummary {
    /**
     * 
     * @type {string}
     * @memberof PromotionSummary
     */
    promotionId: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionSummary
     */
    promotionTitle: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionSummary
     */
    redeemedCount: number;
}

export function PromotionSummaryFromJSON(json: any): PromotionSummary {
    return PromotionSummaryFromJSONTyped(json, false);
}

export function PromotionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promotionId': json['promotion_id'],
        'promotionTitle': json['promotion_title'],
        'redeemedCount': json['redeemed_count'],
    };
}

export function PromotionSummaryToJSON(value?: PromotionSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promotion_id': value.promotionId,
        'promotion_title': value.promotionTitle,
        'redeemed_count': value.redeemedCount,
    };
}


