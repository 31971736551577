/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    readonly isActive: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    rewardTitle: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    containerCategory: string;
}

export function PromotionFromJSON(json: any): Promotion {
    return PromotionFromJSONTyped(json, false);
}

export function PromotionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Promotion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'isActive': json['is_active'],
        'rewardTitle': json['reward_title'],
        'containerCategory': json['container_category'],
    };
}

export function PromotionToJSON(value?: Promotion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'reward_title': value.rewardTitle,
        'container_category': value.containerCategory,
    };
}


