/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CategoryEnum {
    Container = 'CONTAINER',
    Location = 'LOCATION'
}

export function CategoryEnumFromJSON(json: any): CategoryEnum {
    return CategoryEnumFromJSONTyped(json, false);
}

export function CategoryEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryEnum {
    return json as CategoryEnum;
}

export function CategoryEnumToJSON(value?: CategoryEnum | null): any {
    return value as any;
}

