/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BalanceLedgerEventTypeEnum {
    EffectiveSale = 'EFFECTIVE_SALE',
    LateFee = 'LATE_FEE',
    Refund = 'REFUND',
    Withdrawal = 'WITHDRAWAL'
}

export function BalanceLedgerEventTypeEnumFromJSON(json: any): BalanceLedgerEventTypeEnum {
    return BalanceLedgerEventTypeEnumFromJSONTyped(json, false);
}

export function BalanceLedgerEventTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceLedgerEventTypeEnum {
    return json as BalanceLedgerEventTypeEnum;
}

export function BalanceLedgerEventTypeEnumToJSON(value?: BalanceLedgerEventTypeEnum | null): any {
    return value as any;
}

