/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VendorReward
 */
export interface VendorReward {
    /**
     * 
     * @type {string}
     * @memberof VendorReward
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof VendorReward
     */
    readonly rewardType: string;
    /**
     * 
     * @type {string}
     * @memberof VendorReward
     */
    promotion: string;
    /**
     * 
     * @type {string}
     * @memberof VendorReward
     */
    readonly site: string;
    /**
     * 
     * @type {Date}
     * @memberof VendorReward
     */
    redeemedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof VendorReward
     */
    expireAt: Date;
}

export function VendorRewardFromJSON(json: any): VendorReward {
    return VendorRewardFromJSONTyped(json, false);
}

export function VendorRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rewardType': json['reward_type'],
        'promotion': json['promotion'],
        'site': json['site'],
        'redeemedAt': !exists(json, 'redeemed_at') ? undefined : (json['redeemed_at'] === null ? null : new Date(json['redeemed_at'])),
        'expireAt': (new Date(json['expire_at'])),
    };
}

export function VendorRewardToJSON(value?: VendorReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promotion': value.promotion,
        'redeemed_at': value.redeemedAt === undefined ? undefined : (value.redeemedAt === null ? null : value.redeemedAt.toISOString()),
        'expire_at': (value.expireAt.toISOString()),
    };
}


