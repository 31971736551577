/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContainerMovementLedgerEventTypeEnum {
    Issue = 'ISSUE',
    Return = 'RETURN'
}

export function ContainerMovementLedgerEventTypeEnumFromJSON(json: any): ContainerMovementLedgerEventTypeEnum {
    return ContainerMovementLedgerEventTypeEnumFromJSONTyped(json, false);
}

export function ContainerMovementLedgerEventTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerMovementLedgerEventTypeEnum {
    return json as ContainerMovementLedgerEventTypeEnum;
}

export function ContainerMovementLedgerEventTypeEnumToJSON(value?: ContainerMovementLedgerEventTypeEnum | null): any {
    return value as any;
}

