/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelfSuppliedInventory
 */
export interface SelfSuppliedInventory {
    /**
     * 
     * @type {string}
     * @memberof SelfSuppliedInventory
     */
    containerType: string;
    /**
     * 
     * @type {number}
     * @memberof SelfSuppliedInventory
     */
    borrowed: number;
    /**
     * 
     * @type {number}
     * @memberof SelfSuppliedInventory
     */
    returned: number;
    /**
     * 
     * @type {number}
     * @memberof SelfSuppliedInventory
     */
    netChange: number;
}

export function SelfSuppliedInventoryFromJSON(json: any): SelfSuppliedInventory {
    return SelfSuppliedInventoryFromJSONTyped(json, false);
}

export function SelfSuppliedInventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelfSuppliedInventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerType': json['container_type'],
        'borrowed': json['borrowed'],
        'returned': json['returned'],
        'netChange': json['net_change'],
    };
}

export function SelfSuppliedInventoryToJSON(value?: SelfSuppliedInventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'container_type': value.containerType,
        'borrowed': value.borrowed,
        'returned': value.returned,
        'net_change': value.netChange,
    };
}


