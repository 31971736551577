/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralImpactMetrics
 */
export interface ReferralImpactMetrics {
    /**
     * 
     * @type {number}
     * @memberof ReferralImpactMetrics
     */
    numberOfContainersSaved: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralImpactMetrics
     */
    totalWasteSavedFromLandfill?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralImpactMetrics
     */
    numberOfLocationVisits: number;
}

export function ReferralImpactMetricsFromJSON(json: any): ReferralImpactMetrics {
    return ReferralImpactMetricsFromJSONTyped(json, false);
}

export function ReferralImpactMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralImpactMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfContainersSaved': json['number_of_containers_saved'],
        'totalWasteSavedFromLandfill': !exists(json, 'total_waste_saved_from_landfill') ? undefined : json['total_waste_saved_from_landfill'],
        'numberOfLocationVisits': json['number_of_location_visits'],
    };
}

export function ReferralImpactMetricsToJSON(value?: ReferralImpactMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_of_containers_saved': value.numberOfContainersSaved,
        'total_waste_saved_from_landfill': value.totalWasteSavedFromLandfill,
        'number_of_location_visits': value.numberOfLocationVisits,
    };
}


