/* tslint:disable */
/* eslint-disable */
/**
 * AGAIN-AGAIN API
 * backend api for tracking container movement for again-again
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NFCTag
 */
export interface NFCTag {
    /**
     * 
     * @type {string}
     * @memberof NFCTag
     */
    tagId: string;
    /**
     * 
     * @type {string}
     * @memberof NFCTag
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof NFCTag
     */
    signature: string;
}

export function NFCTagFromJSON(json: any): NFCTag {
    return NFCTagFromJSONTyped(json, false);
}

export function NFCTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): NFCTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tagId': json['tag_id'],
        'data': json['data'],
        'signature': json['signature'],
    };
}

export function NFCTagToJSON(value?: NFCTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag_id': value.tagId,
        'data': value.data,
        'signature': value.signature,
    };
}


