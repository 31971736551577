import { extendTheme } from '@chakra-ui/react';
import { Global } from '@emotion/react';

import { PrimaryNav } from './ThemeComponents/Theme.PrimaryNav';
import { SideBarNav } from './ThemeComponents/Theme.SideBarNav';
import { Consumer } from './ThemeComponents/Theme.Consumer';
import { ConsumerSideBarNav } from './ThemeComponents/Theme.ConsumerSideBarNav';
import { SkipLink } from './ThemeComponents/Theme.SkipLink';
import { Header } from './ThemeComponents/Theme.Header';
import { Content } from './ThemeComponents/Theme.Content';
import { Card } from './ThemeComponents/Theme.Card';
import { Logout } from './ThemeComponents/Theme.Logout';
import { Table } from './ThemeComponents/Theme.Table';
import { DropdownFilter } from './ThemeComponents/Theme.DropdownFilter';
import { NextPrevFilter } from './ThemeComponents/Theme.NextPrevFilter';

// Extend the theme to include custom colors, fonts, etc

export const brandColours = {
  // DEVELOPER NOTE:
  // Naming convention of lowercase colour names please
  green: '#44BA7F',
  darkgreen: '#33845B',
  midgreen: '#57c18c',
  lightgreen: '#69c899',
  blue: '#0072CE',
  lightblue: '#e6f1fa',
  midblue: '#d9e7f2',
  darkblue: '#0060A8',
  yellow: '#FFE14C',
  darkgrey: '#979797',
  midgrey: '#DDDDDD',
  lightgrey: '#F1F1F1',
  extralightgrey: '#F7F7F7',
  white: '#fff',
  black: '#000',
  red: '#B00020',
};

export const aaTheme = extendTheme({
  components: {
    SkipLink,
    PrimaryNav,
    SideBarNav,
    Header,
    Content,
    Table,
    Card,
    Logout,
    DropdownFilter,
    NextPrevFilter,
    Consumer,
    ConsumerSideBarNav,
    Link: { baseStyle: { textDecoration: 'underline' } },
  },
  styles: {
    global: {
      body: {
        bg: 'brand.white',
        color: 'brand.black',
        fontFamily: "'Inter', sans-serif",
      },
    },
  },
  colors: {
    brand: { ...brandColours },
  },
  textStyles: {
    h1: {
      fontSize: '34px',
      fontWeight: '500',
      lineHeight: '1.25',
      marginBottom: '3rem',
    },
    h2: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '1.25',
      marginBottom: '1rem',
    },
  },
});

export const GlobalStyles = () => (
  <Global
    styles={`
        abbr[title] {
          text-decoration: none;
          border: none;
        }
    
        html[data-whatintent='mouse'] *:focus,
        html[data-whatintent='touch'] *:focus {
          outline: none;
          box-shadow: none;
        }

        *:focus {
          /* See AGAINAGAIN-559 this ensures focus border styling via box-shadow not hidden behind other elements */
          position:relative; 
          z-index: 1000;
        }
    `}
  />
);
