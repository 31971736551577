import * as Sentry from '@sentry/nextjs';

import { FC, createContext, useContext, useEffect, useState } from 'react';

import { User } from '__generated__';
import { useAPI } from 'utilities/api';

type UserContextType = {
  user: User | undefined;
  updateUser?: (user: User) => Promise<User | void>;
  refetchUser?: () => void;
  isLoading?: boolean;
  error?: Error | undefined;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const api = useAPI();

  const fetchUser = () => {
    setIsLoading(true);
    api.apiV1ProfileMeRetrieve()
      .then((data) => {
        setUser(data);
        setError(undefined);
      })
      .catch((e: any) => {
        setUser(undefined);
        const friendlyError = new Error(
          'We are having some technical issues retrieving your user details. Please contact us on help@againagain.co.',
        );
        setError(friendlyError);
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const updateUser = async (updateUser) => {
    setIsLoading(true);
    return api
      .apiV1ProfileMeUpdate({ user: updateUser })
      .then((data) => {
        setUser(data);
        setError(undefined);
        return data;
      })
      .catch((e: any) => {
        const friendlyError = new Error(
          'We are having some technical issues updating your user details. Please contact us on help@againagain.co.',
        );
        setError(friendlyError);
        Sentry.captureException(e);
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refetchUser = () => fetchUser();

  return (
    <UserContext.Provider value={{ user, updateUser, refetchUser, isLoading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userValue = useContext(UserContext);
  if (!userValue) throw Error('VendorsContext used outside provider');
  return userValue;
};
